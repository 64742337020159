import { render, staticRenderFns } from "./Changepassword.vue?vue&type=template&id=10c63c06&scoped=true"
import script from "./Changepassword.vue?vue&type=script&lang=js"
export * from "./Changepassword.vue?vue&type=script&lang=js"
import style0 from "./Changepassword.vue?vue&type=style&index=0&id=10c63c06&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c63c06",
  null
  
)

export default component.exports